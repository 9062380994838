"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AFFORDABLE_ANNUAL_INCOME_FIELD = exports.AFFORDABLE_MONTHLY_INCOME_FIELD = exports.AFFORDABLE_SEMI_MONTHLY_INCOME_FIELD = exports.AFFORDABLE_BI_WEEKLY_INCOME_FIELD = exports.AFFORDABLE_WEEKLY_INCOME_FIELD = exports.AFFORDABLE_HOURLY_INCOME_FIELD = void 0;
var entities_1 = require("@common/entities");
__exportStar(require("./CHILD_SUPPORT_FIELD"), exports);
__exportStar(require("./GENERAL_ASSISTANCE_FIELD"), exports);
__exportStar(require("./INDIAN_TRUST_FIELD"), exports);
__exportStar(require("./OTHER_NON_WAGE_SOURCE_FIELD"), exports);
__exportStar(require("./PENSIONS_FIELD"), exports);
__exportStar(require("./SUPPLEMENTAL_SECURITY_INCOME_FIELD"), exports);
__exportStar(require("./TEMPORARY_ASSISTANCE_FOR_NEEDY_FAMILIES_FIELD"), exports);
__exportStar(require("./UNEMPLOYMENT_FIELD"), exports);
exports.AFFORDABLE_HOURLY_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Hourly Wage',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
    {
        customType: entities_1.FieldType.Number,
        enabled: true,
        label: 'Hours Per Week',
        name: 'hour_per_week',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Number,
    },
    {
        customType: entities_1.FieldType.Number,
        enabled: true,
        label: 'Weeks Per Year',
        name: 'weeks_per_year',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Number,
    },
];
exports.AFFORDABLE_WEEKLY_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Weekly Salary',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
];
exports.AFFORDABLE_BI_WEEKLY_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Bi-weekly Salary',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
];
exports.AFFORDABLE_SEMI_MONTHLY_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Semi-monthly Salary',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
];
exports.AFFORDABLE_MONTHLY_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Monthly Salary',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
];
exports.AFFORDABLE_ANNUAL_INCOME_FIELD = [
    {
        customType: entities_1.FieldType.Amount,
        enabled: true,
        label: 'Annual Salary',
        name: 'amount',
        required: true,
        sequence: 1,
        type: entities_1.FieldType.Amount,
    },
];

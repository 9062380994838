import { RelationshipType, WaitlistOffer } from '@common/entities';
import { destroy, get, post } from 'config/api/config';

const APPLICANT_APPLICATIONS = '/viewer/applicant/applications';
const APPLICANT_FORGOT_PASSWORD = '/viewer/applicant/forgot-password';
const APPLICANT_RESET_PASSWORD = '/viewer/applicant/resetpassword';
const WAITLIST_ENDPOINT = '/viewer/applicant/waitlist';

export interface ApplicationStatusResponse {
  orgId: number;
  propertyName: string;
  propertyId: number;
  applicantId: string;
  relationshipType: RelationshipType;
  applicationId: number;
  moveInDate: string;
  applicationStatus: string;
  name: string;
  applicantCompletedOn: string;
}
export const getApplicantApplications = async () => {
  const response = await get<ApplicationStatusResponse[]>(
    APPLICANT_APPLICATIONS,
    {
      params: { useCurrentProperty: true },
    },
  );
  return response;
};

export const postResetPassword = async (
  token: string,
  orgId: number,
  propertyId: number,
  password: string,
) => {
  const response = await post(APPLICANT_RESET_PASSWORD, {
    token,
    orgId,
    propertyId,
    password,
  });
  return response;
};

export const requestForgotPassword = async (
  email: string,
  orgId: number,
  propertyId: number,
) => {
  const response = await post(APPLICANT_FORGOT_PASSWORD, {
    email,
    orgId,
    propertyId,
  });
  return response.data;
};

export const addToWaitlist = async (floorplanId: number) => {
  const response = await post(WAITLIST_ENDPOINT, {
    floorplanId,
  });
  return response;
};

export const removeFromWaitlist = async (floorplanId: number) => {
  const response = await destroy(`${WAITLIST_ENDPOINT}/${floorplanId}`);
  return response;
};

export const getWaitlistOffers = async (entrataApplicationId: number) => {
  const response = await get<WaitlistOffer[]>(WAITLIST_ENDPOINT, {
    params: { entrataApplicationId },
  });
  return response;
};

export const acceptWaitlistOffer = async (interestId: number) => {
  const response = await post(`${WAITLIST_ENDPOINT}/accept`, { interestId });
  return response;
};

export const declineWaitlistOffer = async (interestId: number) => {
  const response = await post(`${WAITLIST_ENDPOINT}/decline`, { interestId });
  return response;
};

/* istanbul ignore file */
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageType } from '@common/entities';
import { NotificationStatus } from 'enums/notification-status';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { setQuote } from 'state/slices/unitSelectionSlice';
import { RootState } from 'state/store';

import notification from 'components/notification';
import { useSaveResponse } from 'hooks/useSaveResponse';
import { useWaitlistFunctions } from 'hooks/useWaitlistFunctions';
import handleError from 'utils/handleError';
import { getPageIndex } from 'utils/page-utils';

interface Props {
  activeStepIndex: number;
}
export function useSaveFloorPlanPageData({ activeStepIndex }: Props) {
  const saveResponse = useSaveResponse();

  const { propertyId } = useParams();
  const { isOnAnyWaitlist, isWaitlistEnabled } = useWaitlistFunctions();

  const dispatch = useDispatch();
  const { floorPlan, unit, leaseTerm, moveInDate, quote, quoteLeaseTerm } =
    useSelector((state: RootState) => state.unitSelection);
  const oldFloorPlanPageResponse = useSelector(
    (state: RootState) => state?.response?.value?.floorPlanData,
  );
  const {
    form: { pages },
    activeResponse: { applicationId },
  } = useSelector((state: RootState) => state.application);

  function isFloorPlanPageCompleted() {
    return isWaitlistEnabled ? 
      (floorPlan && unit && leaseTerm) || isOnAnyWaitlist() : 
      floorPlan && unit && leaseTerm;
  }

  function showWarning() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const message = isWaitlistEnabled
      ? 'Please select a floor plan, unit and lease term, or join a waitlist.'
      : 'Please select a floor plan, unit and lease term.';
    notification(message, NotificationStatus.WARNING);
  }

  function setFloorPlanStepsError(errorCount = 0) {
    dispatch(
      updateLeasingJourneyErrors({
        pageIndex: activeStepIndex,
        count: errorCount,
      }),
    );
  }

  function getFloorPlanPageSelectionData() {
    if (floorPlan && unit && leaseTerm) {
      return {
        floorPlanData: {
          floorplanId: floorPlan?.id,
          unitId: unit?.id,
          unitSpaceId: unit?.unitSpaceId,
          leaseTermId: leaseTerm?.id,
          termMonth: leaseTerm?.termMonth,
          moveInDate,
        },
      };
    }

    if (oldFloorPlanPageResponse) {
      const { floorplanId, unitId, unitSpaceId, leaseTermId, termMonth } =
        oldFloorPlanPageResponse;

      return {
        floorPlanData: {
          floorplanId,
          unitId,
          unitSpaceId,
          leaseTermId,
          termMonth,
          moveInDate,
        },
      };
    }

    return {};
  }

  function prepareFloorPlanRequest(targetIndex: number, errorCount = 0) {
    const userResponse = getFloorPlanPageSelectionData();

    return {
      currentPageId: pages[activeStepIndex].id,
      targetPageId: pages[targetIndex].id,
      applicationId,
      responses: [userResponse],
      propertyId: Number(propertyId),
      errorCount,
    };
  }

  const updateQuotes = async () => {
    const quotePageIndex = getPageIndex(pages, PageType.Quotes);
    if (quotePageIndex < 0) return;
    try {
      await saveResponse({
        currentPageId: pages[quotePageIndex]?.id,
        applicationId,
        propertyId: Number(propertyId),
        responses: [],

        errorCount: 0,
      });
      dispatch(setQuote({ quote: null, quoteLeaseTerm: null }));
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const shouldProceedTheRequest = () => {
    const quotesPageIndex = getPageIndex(pages, PageType.Quotes);
    if (quotesPageIndex < 0) return true;
    if (!quote || !quoteLeaseTerm) return true;
    const { floorPlanData } = getFloorPlanPageSelectionData();
    if (!floorPlanData) return true;
    const { floorplanId, unitSpaceId, leaseTermId } = floorPlanData as any;

    const {
      unitDetails: { id, floorId },
    } = quote;
    if (
      quoteLeaseTerm === Number(leaseTermId) &&
      floorplanId === floorId &&
      unitSpaceId === id
    ) {
      return false;
    }
    return true;
  };

  async function saveFloorPlanPage(targetIndex: number) {
    let errorCount = 0;
    if (!isFloorPlanPageCompleted()) {
      errorCount += 1;
      setFloorPlanStepsError(errorCount);
      if (targetIndex > activeStepIndex) {
        showWarning();
        return false;
      }
    }
    if (!shouldProceedTheRequest()) return true;
    try {
      const requestData = prepareFloorPlanRequest(targetIndex, errorCount);

      await saveResponse(requestData);
      await updateQuotes();
      setFloorPlanStepsError(errorCount);
      return true;
    } catch (error: any) {
      errorCount + 1;
      handleError(
        `Form Viewer - Error saving floor plan page: ${error}`,
        `${error.message}`,
      );
      setFloorPlanStepsError(errorCount);
      return false;
    }
  }

  return saveFloorPlanPage;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FOSTER_CHILDREN_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.FOSTER_CHILDREN_FIELDS = [
    {
        label: 'Foster Children',
        type: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'anticipated_addition_foster_child',
        customType: entities_1.FieldType.YesNo,
        required: true,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'How Many Foster Children?',
        type: entities_1.FieldType.Number,
        sequence: 2,
        name: 'anticipated_addition_foster_child_val',
        customType: entities_1.FieldType.Number,
        required: true,
        enabled: true,
        isDependent: true,
    },
];

/* istanbul ignore file */
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { NotificationStatus } from 'enums/notification-status';
import { setSaveCompleted } from 'state/slices/actionsSlice';
import { RootState } from 'state/store';
import { useSelector } from 'state/storeHooks';

import notification from 'components/notification';
import { useSaveResponse } from 'hooks/useSaveResponse';
import { useWaitlistFunctions } from 'hooks/useWaitlistFunctions';

interface FloorPlanData {
  floorplanId: number;
  unitId: number;
  unitSpaceId: number;
  leaseTermId: string;
  termMonth: string;
  moveInDate: string;
}

interface SaveFloorPlanRequestData {
  currentPageId: string;
  targetPageId: string;
  applicationId: string;
  propertyId: number;
  responses: [
    {
      floorPlanData: FloorPlanData;
    },
  ];
  errorCount: number;
}

interface SaveFloorPlanProps {
  floorPlan: any;
  unit: any;
  leaseTerm: any;
  pages: any[];
  propertyId: number;
  moveInDate: any;
}

export const useSaveFloorPlanPage = (props: SaveFloorPlanProps) => {
  const dispatch = useDispatch();
  const saveResponse = useSaveResponse();

  const { mutateAsync: updateResponse } = useMutation({
    mutationFn: saveResponse,
    onSettled: () => {
      dispatch(setSaveCompleted(true));
    },
  });

  const { floorPlan, unit, leaseTerm, pages, propertyId, moveInDate } = props;
  const { isOnAnyWaitlist, isWaitlistEnabled } = useWaitlistFunctions();

  const { applicationId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  const saveFloorPlanPage = async (
    targetStepIndex: number,
    activeStepIndex: number,
  ) => {
    if (!floorPlan || !unit || !leaseTerm) {
      dispatch(setSaveCompleted(true));
      return false;
    }

    const isMovingForward = targetStepIndex > activeStepIndex;
    const isSelected = isWaitlistEnabled ? (floorPlan && unit && leaseTerm) || isOnAnyWaitlist() : floorPlan && unit && leaseTerm;

    if (isMovingForward && !isSelected) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      const message = isWaitlistEnabled
      ? 'Please select a floor plan, unit and lease term, or join a waitlist.'
      : 'Please select a floor plan, unit and lease term.';
    notification(message, NotificationStatus.WARNING);
      return false;
    }

    const requestData: SaveFloorPlanRequestData = {
      currentPageId: pages[activeStepIndex].id,
      targetPageId: pages[targetStepIndex].id,
      applicationId,
      propertyId,
      responses: [
        {
          floorPlanData: {
            floorplanId: floorPlan.id,
            unitId: unit.id,
            unitSpaceId: unit.unitSpaceId,
            leaseTermId: leaseTerm.id,
            termMonth: leaseTerm.termMonth,
            moveInDate,
          },
        },
      ],
      errorCount: 0,
    };
    await updateResponse(requestData);

    return true;
  };

  return saveFloorPlanPage;
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidationRules = getValidationRules;
var fieldTypeValidation_1 = require("./fieldTypeValidation");
var fieldValidation_1 = require("./fieldValidation");
/**
 * Generates validation rules for a form field based on:
 * - Field type (FIELD_TYPE_VALIDATION)
 * - Field name (FIELD_VALIDATION)
 * - Required flag (adds a required message if true)
 * - Additional validation overrides via props
 *
 * Note:
 * - Optional fields still enforce validation (e.g., pattern, minLength) if a value is provided.
 */
function getValidationRules(_a) {
    var name = _a.name, type = _a.type, label = _a.label, required = _a.required, _b = _a.additionalValidation, additionalValidation = _b === void 0 ? {} : _b;
    var baseRules = __assign(__assign({}, (fieldTypeValidation_1.FIELD_TYPE_VALIDATION[type] || {})), (fieldValidation_1.FIELD_VALIDATION[name] || {}));
    var mergedValidation = __assign(__assign(__assign({}, baseRules), (required ? { required: "".concat(label, " is required") } : {})), additionalValidation);
    return mergedValidation;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

interface Permission {
  [key: string]: boolean;
}
export interface UserPermissionSlice {
  permission: Permission;
}

const initialState: UserPermissionSlice = {
  permission: {},
};

export const userPermissionsSlice = createSlice({
  name: 'userPermission',
  initialState,
  reducers: {
    setPermission: (state, action: PayloadAction<Permission>) => {
      // eslint-disable-next-line no-param-reassign
      state.permission = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setPermission } = userPermissionsSlice.actions;
export default userPermissionsSlice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEMPORARY_ASSISTANCE_FOR_NEEDY_FAMILIES_FIELDS = void 0;
var entities_1 = require("@common/entities");
var FIELD_1 = require("./FIELD");
exports.TEMPORARY_ASSISTANCE_FOR_NEEDY_FAMILIES_FIELDS = {
    name: 'temporary_assistance_for_needy_families',
    label: 'Temporary Assistance For Needy Families',
    customType: entities_1.IncomeTypes.TemporaryAssistanceForNeedyFamilies,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.AffordableInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: FIELD_1.FIELDS,
};

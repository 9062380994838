"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PENSIONS_FIELDS = void 0;
var entities_1 = require("@common/entities");
var FIELD_1 = require("./FIELD");
exports.PENSIONS_FIELDS = {
    name: 'pensions',
    label: 'Pensions',
    customType: entities_1.IncomeTypes.Pensions,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.AffordableInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: FIELD_1.FIELDS,
};

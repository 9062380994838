import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

export const useLPPermissionCheck = () => {
  const { isLeadProfile = false } = useSelector(
    (state: RootState) => state.auth,
  );

  const isAuthorize = (permission = false) => {
    if (!isLeadProfile) return true;
    return isLeadProfile && permission;
  };

  return isAuthorize;
};

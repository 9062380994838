"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPLEMENTAL_SECURITY_INCOME_FIELDS = void 0;
var entities_1 = require("@common/entities");
var FIELD_1 = require("./FIELD");
exports.SUPPLEMENTAL_SECURITY_INCOME_FIELDS = {
    name: 'supplemental_security_income',
    label: 'Supplemental Security Income',
    customType: entities_1.IncomeTypes.SupplementalSecurityIncome,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.AffordableInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: FIELD_1.FIELDS,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyRoute = void 0;
var PropertyRoute = /** @class */ (function () {
    function PropertyRoute() {
        this.propertyId = 0;
        this.propertyName = '';
        this.routeId = '';
        this.routeName = '';
    }
    return PropertyRoute;
}());
exports.PropertyRoute = PropertyRoute;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SSN_EXCEPTION_OPTIONS = exports.OWNERSHIP_STATUS = exports.DISABILITY_TYPE_OPTIONS = exports.HIGHER_LEARNING_STATUS_OPTIONS = exports.YES_NO_OPTIONS = exports.PET_GENDER = exports.INCOME_TYPE_AND_FIELD_MAPPING = exports.INCOME_PAYMENT_INTERVAL = exports.AFFORDABLE_PAYMENT_INTERVAL = exports.CONVENTIONAL_PAYMENT_INTERVAL = void 0;
var entities_1 = require("@common/entities");
var income_field_1 = require("./block-field-templates/income_field");
exports.CONVENTIONAL_PAYMENT_INTERVAL = (_a = {},
    _a[entities_1.ConventionalInterval.Monthly] = 'Monthly',
    _a[entities_1.ConventionalInterval.Weekly] = 'Weekly',
    _a[entities_1.ConventionalInterval.Yearly] = 'Yearly',
    _a);
exports.AFFORDABLE_PAYMENT_INTERVAL = (_b = {},
    _b[entities_1.AffordableInterval.Hourly] = 'Hourly',
    _b[entities_1.AffordableInterval.Weekly] = 'Weekly',
    _b[entities_1.AffordableInterval.BiWeekly] = 'Bi-Weekly',
    _b[entities_1.AffordableInterval.SemiMonthly] = 'Semi Monthly',
    _b[entities_1.AffordableInterval.Monthly] = 'Monthly',
    _b[entities_1.AffordableInterval.Annually] = 'Annually',
    _b);
exports.INCOME_PAYMENT_INTERVAL = __assign(__assign({}, exports.CONVENTIONAL_PAYMENT_INTERVAL), exports.AFFORDABLE_PAYMENT_INTERVAL);
exports.INCOME_TYPE_AND_FIELD_MAPPING = (_c = {},
    _c[entities_1.IncomeTypes.CurrentEmployer] = income_field_1.CURRENT_EMPLOYER_FIELDS,
    _c[entities_1.IncomeTypes.PreviousEmployer] = income_field_1.PREVIOUS_EMPLOYER_FIELDS,
    _c[entities_1.IncomeTypes.SelfEmployer] = income_field_1.SELF_EMPLOYER_FIELDS,
    _c[entities_1.IncomeTypes.OtherIncome] = income_field_1.OTHER_INCOME_FIELDS,
    _c[entities_1.IncomeTypes.ChildSupport] = income_field_1.CHILD_SUPPORT_FIELDS,
    _c[entities_1.IncomeTypes.GeneralAssistance] = income_field_1.GENERAL_ASSISTANCE_FIELDS,
    _c[entities_1.IncomeTypes.IndianTrust] = income_field_1.INDIAN_TRUST_FIELDS,
    _c[entities_1.IncomeTypes.OtherNonWageSource] = income_field_1.OTHER_NON_WAGE_SOURCE_FIELDS,
    _c[entities_1.IncomeTypes.Pensions] = income_field_1.PENSIONS_FIELDS,
    _c[entities_1.IncomeTypes.SupplementalSecurityIncome] = income_field_1.SUPPLEMENTAL_SECURITY_INCOME_FIELDS,
    _c[entities_1.IncomeTypes.TemporaryAssistanceForNeedyFamilies] = income_field_1.TEMPORARY_ASSISTANCE_FOR_NEEDY_FAMILIES_FIELDS,
    _c[entities_1.IncomeTypes.Unemployment] = income_field_1.UNEMPLOYMENT_FIELDS,
    _c);
exports.PET_GENDER = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
exports.YES_NO_OPTIONS = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
];
exports.HIGHER_LEARNING_STATUS_OPTIONS = [
    {
        label: 'Full Time',
        value: 'full_time',
    },
    {
        label: 'Part Time',
        value: 'part_time',
    },
];
exports.DISABILITY_TYPE_OPTIONS = [
    {
        value: 'disability_mobility',
        label: 'Mobility',
    },
    {
        value: 'disability_hearing',
        label: 'Hearing',
    },
    {
        value: 'disability_visual',
        label: 'Visual',
    },
    {
        value: 'disability_mental',
        label: 'Mental',
    },
];
exports.OWNERSHIP_STATUS = [
    { label: 'Owner', value: 'owner' },
    {
        label: 'Renter',
        value: 'renter',
    },
];
exports.SSN_EXCEPTION_OPTIONS = [
    {
        label: 'Individual who does not contend eligible immigration status.',
        value: 'ssn_exception_description_1',
    },
    {
        label: 'Individuals age 62 or older as of January 31, 2010, whose initial determination of eligibility in either a Multifamily or Public and Indian Housing program was begun prior to January 31, 2010 (a break in assistance does not void the exemption).',
        value: 'ssn_exception_description_2',
    },
    {
        label: 'New household member under the age of 6 where disclosure of SSN is delayed for 90 - 180 days.',
        value: 'ssn_exception_description_3',
    },
    {
        label: 'Foster child or adult whose SSN has not been disclosed by the foster agency and for whom HUD has issues a waiver.',
        value: 'ssn_exception_description_4',
    },
];

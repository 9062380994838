"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OTHER_NON_WAGE_SOURCE_FIELDS = void 0;
var entities_1 = require("@common/entities");
var FIELD_1 = require("./FIELD");
exports.OTHER_NON_WAGE_SOURCE_FIELDS = {
    name: 'other_non_wage_source',
    label: 'Other Non Wage Source',
    customType: entities_1.IncomeTypes.OtherNonWageSource,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.AffordableInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: FIELD_1.FIELDS,
};

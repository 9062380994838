/* istanbul ignore file */
import { PageType } from '@common/entities';

import { AnyPage } from 'types/page';

interface ParsedResponse {
  pages: {
    pageId: string;
    errorCount: number | null;
    isCurrentPage?: boolean;
  }[];
}

export function getFilteredErrors(
  pages: AnyPage[],
  parsedResponses?: ParsedResponse,
): { filteredPages: AnyPage[]; stepsStatus: (number | null)[] } {
  const filteredPages = pages.filter(
    (page) => page.type !== PageType.Payment && page.type !== PageType.ThankYou,
  );

  if (!parsedResponses) {
    const stepsStatus = filteredPages.map(() => null);
    return { filteredPages, stepsStatus };
  }

  const pageIdErrorMapping = parsedResponses.pages.reduce(
    (acc, res) => ({
      ...acc,
      [res.pageId]: res.errorCount,
    }),
    {} as { [key: string]: number | null },
  );

  const stepsStatus = filteredPages.map((page) => {
    if (
      pageIdErrorMapping &&
      page.id !== undefined &&
      page.id in pageIdErrorMapping
    ) {
      return pageIdErrorMapping[page.id];
    }
    return null;
  });

  return { filteredPages, stepsStatus };
}

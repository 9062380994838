"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AFFORDABLE_QUESTION_DOCUMENT_MAPPING = void 0;
var enums_1 = require("./enums");
exports.AFFORDABLE_QUESTION_DOCUMENT_MAPPING = (_a = {},
    _a[enums_1.BlockType.Student] = enums_1.AffordableDocumentTypeId.Student,
    _a[enums_1.BlockType.Disability] = enums_1.AffordableDocumentTypeId.Disability,
    _a[enums_1.BlockType.PoliceSecurityOfficer] = enums_1.AffordableDocumentTypeId.PoliceSecurityOfficer,
    _a[enums_1.BlockType.MilitaryVeteran] = enums_1.AffordableDocumentTypeId.Veteran,
    _a[enums_1.BlockType.HousedDeclaredDisaster] = enums_1.AffordableDocumentTypeId.Disaster,
    _a);

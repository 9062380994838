import { useDispatch, useSelector } from 'react-redux';
import { PageType, PropertySettingType } from '@common/entities';
import { updateWaitlistSelections } from 'state/slices/unitSelectionSlice';
import { RootState } from 'state/store';

import { useSaveResponse } from 'hooks/useSaveResponse';
import { 
    acceptWaitlistOffer as acceptWaitlistOfferService,
    addToWaitlist as addToWaitlistService, 
    declineWaitlistOffer as declineWaitlistOfferService,
    removeFromWaitlist as removeFromWaitlistService,
  } from 'services/applicant';
import { getPageIndex } from 'utils/page-utils';



export function useWaitlistFunctions() {
  const saveResponse = useSaveResponse();
  const dispatch = useDispatch();
  const { waitlistSelections: currentWaitlistSelections } = useSelector(
    (state: RootState) => state.unitSelection,
  );
  const { propertyId, pages, applicationId } = useSelector(
    (state: RootState) => {
      const { form, applicant } = state.application;
      return {
        pages: form.pages,
        propertyId: applicant.propertyId,
        applicationId: applicant.currentResponse.applicationId,
      };
    },
  );
  const { propertySettings } = useSelector(
    (state: RootState) => state.propertySettings,
  );

  const isWaitlistEnabled = propertySettings.some(
    (setting) => setting.settingType === PropertySettingType.WaitlistEnabled && setting.value === true
  );

  async function addToWaitlistInApplicantResponse(
    floorPlanId: number,
  )
  {
    const floorplanIds = [...(currentWaitlistSelections?.floorplanIds || [])];
    if (!floorplanIds.includes(floorPlanId)) {
      floorplanIds.push(floorPlanId);
    }
    const updatedWaitlistSelections = {
      floorplanIds,
    };

    await updateWaitlistInApplicantResponse(updatedWaitlistSelections);
    dispatch(updateWaitlistSelections({ waitlistSelections: updatedWaitlistSelections }));
  }

    async function removeFromWaitlistInApplicantResponse(floorPlanId: number)
    {
      const floorplanIds = [...(currentWaitlistSelections?.floorplanIds || [])];
      if (floorplanIds.includes(floorPlanId)) {
        floorplanIds.splice(floorplanIds.indexOf(floorPlanId), 1);
      }
      const updatedWaitlistSelections = {
        floorplanIds,
      };
  
      await updateWaitlistInApplicantResponse(updatedWaitlistSelections);
      dispatch(updateWaitlistSelections({ waitlistSelections: updatedWaitlistSelections }));
    }

  async function updateWaitlistInApplicantResponse(updatedWaitlistSelections: any)
  {
    const pageIndex = getPageIndex(pages, PageType.FloorPlans);
    try {
      const applicantResponse = {
        currentPageId: pages[pageIndex]?.id,
        applicationId,
        propertyId,
        responses: [
          { waitlistSelections: updatedWaitlistSelections}
        ],
        errorCount: 0,
      };
      await saveResponse(applicantResponse);
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async function addToWaitlist(floorPlanId: number) {
    await addToWaitlistService(floorPlanId);
    // If successful, add to applicant response
    addToWaitlistInApplicantResponse(floorPlanId);
  }

  async function removeFromWaitlist(floorPlanId: number) {
    await removeFromWaitlistService(floorPlanId);
    // If successful, add to applicant response
    removeFromWaitlistInApplicantResponse(floorPlanId);
  }

  function isOnWaitlist(floorPlanId: number) {
    return currentWaitlistSelections?.floorplanIds?.includes(floorPlanId);
  }

  function isOnAnyWaitlist() {
    return currentWaitlistSelections?.floorplanIds?.length > 0;
  }

  async function acceptWaitlistOffer(interestId: number) {
    const response = await acceptWaitlistOfferService(interestId);
    return response;
  }

  async function declineWaitlistOffer(interestId: number) {
    const response = await declineWaitlistOfferService(interestId);
    return response;
  }

  return {
    addToWaitlist,
    removeFromWaitlist,
    isOnWaitlist,
    isOnAnyWaitlist,
    isWaitlistEnabled,
    acceptWaitlistOffer,
    declineWaitlistOffer,
  };
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OTHER_INCOME_FIELDS = void 0;
var entities_1 = require("@common/entities");
var entities_2 = require("@common/entities");
exports.OTHER_INCOME_FIELDS = {
    name: 'other_income',
    label: 'Other Income',
    customType: entities_1.IncomeTypes.OtherIncome,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.ConventionalInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: [
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            label: 'Income Type',
            name: 'institution_name',
            required: true,
            sequence: 1,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            isAddress: true,
            label: 'Address Line 1',
            name: 'institution_street_line1',
            required: true,
            sequence: 2,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            isAddress: true,
            label: 'Address Line 2',
            name: 'institution_street_line2',
            required: true,
            sequence: 3,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            isAddress: true,
            label: 'City',
            name: 'institution_city',
            required: true,
            sequence: 4,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.CountryDropdown,
            enabled: true,
            isAddress: true,
            label: 'Country',
            name: 'institution_country_code',
            options: [],
            required: true,
            sequence: 5,
            type: entities_2.FieldType.CountryDropdown,
        },
        {
            customType: entities_2.FieldType.StateDropdown,
            enabled: true,
            isAddress: true,
            label: 'State',
            name: 'institution_state_code',
            options: [],
            required: true,
            sequence: 6,
            type: entities_2.FieldType.StateDropdown,
        },
        {
            customType: entities_2.FieldType.ZipCode,
            enabled: true,
            isAddress: true,
            label: 'Zip Code',
            name: 'institution_postal_code',
            required: true,
            sequence: 7,
            type: entities_2.FieldType.ZipCode,
        },
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            label: 'Contact Name',
            name: 'contact_name',
            required: true,
            sequence: 8,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.PhoneNumber,
            enabled: true,
            label: 'Contact Phone',
            name: 'contact_phone_number',
            required: true,
            sequence: 9,
            type: entities_2.FieldType.PhoneNumber,
        },
        {
            customType: entities_2.FieldType.Amount,
            enabled: true,
            label: 'Gross Income',
            name: 'amount',
            required: true,
            sequence: 10,
            type: entities_2.FieldType.Amount,
        },
        {
            customType: entities_2.FieldType.Text,
            enabled: true,
            label: 'Job Title',
            name: 'position_or_title',
            required: true,
            sequence: 11,
            type: entities_2.FieldType.Text,
        },
        {
            customType: entities_2.FieldType.TextArea,
            enabled: true,
            label: 'Description',
            name: 'description',
            required: true,
            sequence: 12,
            type: entities_2.FieldType.TextArea,
        },
        {
            customType: entities_2.FieldType.Date,
            enabled: true,
            label: 'End Date',
            name: 'date_ended',
            required: true,
            sequence: 13,
            type: entities_2.FieldType.Date,
        },
        {
            customType: entities_2.FieldType.File,
            enabled: true,
            label: 'Upload Proof of Income',
            name: 'document_upload',
            required: true,
            sequence: 14,
            type: entities_2.FieldType.File,
        },
    ],
};

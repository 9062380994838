import { Special } from '@common/entities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

import { AddonOption } from 'types/addon';
import { FloorPlan, LeaseTerm, Unit } from 'types/floorplan-page-types';
import type { Quote } from 'types/page';
import { getTodaysDate } from 'utils/date';

type UnitReducer = {
  floorPlan: FloorPlan | null;
  unit: Unit | null;
  leaseTerm: LeaseTerm | null;
  moveInDate: string;
};

type AddonReducer = {
  addOns: AddonOption[];
};

type QuoteReducer = {
  quote: Quote | null;
  quoteLeaseTerm: number | null;
};

type SpecialsReducer = {
  propertySpecials: any;
  floorplanSpecials: any;
  unitSpaceSpecials: any;
  unitTypeSpecials: any;
  promoCodeSpecials: any;
  selectedSpecials: any[];
};

type WaitlistSelectionsReducer = {
  waitlistSelections: any;
}

export type UnitSelectionReducer = UnitReducer & AddonReducer & QuoteReducer & SpecialsReducer & WaitlistSelectionsReducer;

const initialState: UnitSelectionReducer = {
  floorPlan: null,
  unit: null,
  leaseTerm: null,
  moveInDate: getTodaysDate(),
  addOns: [],
  selectedSpecials: [],
  waitlistSelections: null,
  quote: null,
  quoteLeaseTerm: null,
  propertySpecials: null,
  floorplanSpecials: null,
  unitSpaceSpecials: null,
  unitTypeSpecials: null,
  promoCodeSpecials: null,
};

export const unitSelectionSlice = createSlice({
  name: 'unitSelection',
  initialState,
  reducers: {
    updateUnitSelection: (
      state,
      action: PayloadAction<Partial<UnitReducer>>,
    ) => {
      return { ...state, ...action.payload };
    },
    resetUnitSelection: (state) => {
      return {
        ...state,
        floorPlan: null,
        unit: null,
        leaseTerm: null,
        moveInDate: getTodaysDate(),
      };
    },
    updateAddon: (state, action: PayloadAction<AddonOption[]>) => {
      return { ...state, addOns: action.payload };
    },
    updateSelectedSpecials: (state, action: PayloadAction<Special[]>) => {
      return { ...state, selectedSpecials: action.payload };
    },
    updateWaitlistSelections: (state, action: PayloadAction<WaitlistSelectionsReducer>) => {
      const { floorplanIds } = action.payload.waitlistSelections;
      return { ...state, waitlistSelections: {floorplanIds} };
    },
    setQuote: (state, action: PayloadAction<QuoteReducer>) => {
      const { quote, quoteLeaseTerm } = action.payload;
      return { ...state, quote, quoteLeaseTerm };
    },
    updateSpecials: (state, action: PayloadAction<Partial<SpecialsReducer>>) => {
      return { ...state, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  resetUnitSelection,
  setQuote,
  updateAddon,
  updateUnitSelection,
  updateSpecials,
  updateSelectedSpecials,
  updateWaitlistSelections,
} = unitSelectionSlice.actions;

export default unitSelectionSlice.reducer;

/* istanbul ignore file */
import { useDispatch } from 'react-redux';
import { queryClient } from 'AppWrapper';
import { updateUnitSelection, updateWaitlistSelections } from 'state/slices/unitSelectionSlice';

import { useSaveResponse } from 'hooks/useSaveResponse';
import { getAvailableUnitsAndLeaseTerm, getFloorPlans } from 'services';
import { addToWaitlist as addToWaitlistService } from 'services/applicant';
import type { FloorPlan, LeaseTerm, Unit } from 'types/floorplan-page-types';
import { getTodaysDate } from 'utils/date';
import handleError from 'utils/handleError';

export function useSetFloorPlanPageDataFromLink() {
  const dispatch = useDispatch();

  const saveResponse = useSaveResponse();

  async function updateResponse(
    currentPageId: string,
    applicationId: string,
    propertyId: number,
    data: { [key: string]: any },
    responseId?: string,
  ) {
    try {
      await saveResponse(
        {
          currentPageId,
          applicationId,
          propertyId,
          responses: [data],
        },
        responseId,
      );
    } catch {
      throw new Error('Error while saving floorplan details.');
    }
  }

  async function fetchAvailableUnitsAndLeaseTerm(
    propertyId: number,
    floorPlanId: number,
    moveInDate: string,
    unitSpaceId: number,
    termMonth: string,
  ) {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['units', floorPlanId, moveInDate],
        queryFn: () =>
          getAvailableUnitsAndLeaseTerm(propertyId, floorPlanId, moveInDate),
      });

      const selectedUnit = data.find(
        (i: Unit) => i.unitSpaceId === unitSpaceId,
      );
      if (!selectedUnit) throw new Error('Unit is not available.');

      const selectedLeaseTerm = selectedUnit.leaseTerms.find(
        (i: LeaseTerm) => i.termMonth === termMonth,
      );
      if (!selectedLeaseTerm) throw new Error('Lease term not found.');

      return { unit: selectedUnit, leaseTerm: selectedLeaseTerm };
    } catch {
      throw new Error('Error while fetching available unit');
    }
  }

  async function fetchFloorPlans(
    propertyId: number,
    floorPlanId: number,
    moveInDate: string,
  ) {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['floor-plans', propertyId, moveInDate],
        queryFn: () => getFloorPlans(propertyId, moveInDate),
      });

      return data.find((i: FloorPlan) => i.id === floorPlanId);
    } catch {
      throw new Error('Error while fetching floor plans.');
    }
  }

  function doesUrlSpecifyWaitlistSelection(link: string) {
    const url = new URL(link);
    const pathArr = url.pathname.split('/');
    return (pathArr.includes('from_waitlist'));
  }


  function extractUnitSelectionData(link: string) {
    const url = new URL(link);
    const todaysDate = getTodaysDate();
    const moveInDate = url.searchParams.get('lease_start_date') ?? todaysDate;
    const pathArr = url.pathname.split('/');
    const floorplanId = pathArr[pathArr.indexOf('property_floorplan[id]') + 1];
    const unitSpaceId = pathArr[pathArr.indexOf('unit_space[id]') + 1];
    const termMonth = pathArr[pathArr.indexOf('term_month') + 1];
    return {
      moveInDate,
      floorplanId: Number(floorplanId),
      unitSpaceId: Number(unitSpaceId),
      termMonth,
    };
  }

  function extractWaitlistSelectionData(link: string) {
    const url = new URL(link);
    const pathArr = url.pathname.split('/');
    const waitlistFloorplanId = pathArr[pathArr.indexOf('property_floorplan_id') + 1];
    const waitlistMoveInDate = pathArr[pathArr.indexOf('desired_move_in_date') + 1];
    return {
      waitlistMoveInDate,
      waitlistFloorplanId: Number(waitlistFloorplanId),}
  }

  function isValidURL(
    floorPlanId: number,
    unitSpaceId: number,
    termMonth: string,
    url: string,
  ) {
    const missingParameter: string[] = [];

    if (!floorPlanId) missingParameter.push('Floor Plan Id');
    if (!unitSpaceId) missingParameter.push('Unit Space Id');
    if (!termMonth) missingParameter.push('Lease Month');

    if (missingParameter.length) {
      handleError(
        `Form Viewer - ${missingParameter.join(', ')} missing parameter in redirection URL - ${url}`,
      );
    }
    return missingParameter.length === 0;
  }

  async function initialize(
    url: string,
    propertyId: number,
    applicationId: string,
    pageId: string,
    responseId?: string,
  ) {

    if (doesUrlSpecifyWaitlistSelection(url)) {
      return initializeWaitlistSelection(
        url,
        propertyId,
        applicationId,
        pageId,
        responseId,
      );
    }

    return initializeUnitSelection(
      url,
      propertyId,
      applicationId,
      pageId,
      responseId,
    );
  }

  async function initializeWaitlistSelection(
    url: string,
    propertyId: number,
    applicationId: string,
    pageId: string,
    responseId?: string,
  )
  {
    const {
      waitlistFloorplanId,
    } = extractWaitlistSelectionData(url);

    if (!waitlistFloorplanId) throw new Error('Missing floorplan id for waitlist.');
    try {
      await addToWaitlistService(waitlistFloorplanId);
      const waitlistSelections = { waitlistSelections: {floorplanIds:[waitlistFloorplanId] }}
      await updateResponse(
        pageId,
        applicationId,
        propertyId,
        waitlistSelections,
        responseId,
      );
      dispatch(updateWaitlistSelections(waitlistSelections));
      return 0;
    }
    catch(err: any) {
      handleError(
        `Form Viewer - Error updating waitlist selection: ${err}`,
        `${err.message}`,
      );

      return 1;
    }

  }

  async function initializeUnitSelection(
    url: string,
    propertyId: number,
    applicationId: string,
    pageId: string,
    responseId?: string,
  ) {
    // TODO: Temp code
    const todaysDate = getTodaysDate();

    try {
      const {
        moveInDate,
        floorplanId: floorPlanId,
        unitSpaceId,
        termMonth,
      } = extractUnitSelectionData(url);

      if (!isValidURL(floorPlanId, unitSpaceId, termMonth, url)) {
        return 1;
      }

      if (!floorPlanId || !moveInDate) throw new Error('Missing parameter.');
      const floorPlan = await fetchFloorPlans(
        propertyId,
        floorPlanId,
        todaysDate,
      );

      if (!floorPlan) throw new Error('Floor plan is not available.');

      const { unit, leaseTerm } = await fetchAvailableUnitsAndLeaseTerm(
        propertyId,
        floorPlanId,
        todaysDate,
        unitSpaceId,
        termMonth,
      );

      if (!leaseTerm) throw new Error('Lease term is not available.');

      await updateResponse(
        pageId,
        applicationId,
        propertyId,
        {
          floorPlanData: {
            floorplanId: floorPlan.id,
            unitId: unit.id,
            unitSpaceId: unit.unitSpaceId,
            leaseTermId: leaseTerm.id,
            termMonth: leaseTerm.termMonth,
            moveInDate,
          },
        },
        responseId,
      );

      dispatch(
        updateUnitSelection({
          floorPlan,
          unit,
          leaseTerm,
          moveInDate: todaysDate,
        }),
      );

      return 0;
    } catch (err: any) {
      handleError(
        `Form Viewer - Error updating unit selection: ${err}`,
        `${err.message}`,
      );

      return 1;
    }
  }

  return initialize;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyRouteApplications = void 0;
var PropertyRouteApplications = /** @class */ (function () {
    function PropertyRouteApplications() {
        this.propertyId = 0;
        this.propertyName = '';
        this.applications = [];
    }
    return PropertyRouteApplications;
}());
exports.PropertyRouteApplications = PropertyRouteApplications;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitlistOffer = void 0;
var WaitlistOffer = /** @class */ (function () {
    function WaitlistOffer() {
        this.applicationName = '';
        this.moveInDate = '';
        this.entrataApplicationId = 0;
        this.interestId = 0;
        this.floorplanName = '';
        this.numberOfBedrooms = 0;
        this.numberOfBathrooms = 0;
        this.unitName = '';
        this.reserveUntil = '';
    }
    return WaitlistOffer;
}());
exports.WaitlistOffer = WaitlistOffer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELD_VALIDATION = void 0;
exports.FIELD_VALIDATION = {
    applicant_title: { minLength: 2 },
    applicant_name_first: { minLength: 3 },
    applicant_name_middle: { minLength: 3 },
    applicant_name_last: { minLength: 3 },
    applicant_primary_number: {
        pattern: {
            value: /^\(([2-9][0-9]{2})\)\s\d{3}\s-\s\d{4}$/,
            message: 'Please enter a valid phone number',
        },
    },
    applicant_birth_date: {},
    applicant_marital_status: {},
    applicant_employment_status: {},
    applicant_job_title: {},
    applicant_current_annual_income: {
        pattern: {
            value: /^\d+(\.\d{1,2})?$/,
            message: 'Enter a valid income amount.',
        },
    },
    applicant_employer_postal_code: {
        pattern: {
            value: /^(\d{5}(-\d{4})?)$/,
            message: 'Invalid ZIP code format.',
        },
    },
    applicant_email_address: {
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Please enter a valid email address.',
        },
    },
    applicant_social_security_number: {
        pattern: {
            value: /(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}/,
            message: 'Invalid Social Security Number (SSN).',
        },
    },
    // Pets
    applicant_pet_type: {},
    applicant_pet_assistance_animal: {},
    applicant_pet_breed: {},
    applicant_pet_weight: {},
    applicant_pet_spyed: {},
    applicant_pet_house_broken: {},
    applicant_pet_name: {},
    applicant_pet_color: {},
    applicant_pet_age: {},
    applicant_pet_gender: {},
    applicant_pet_spyed_date: {},
    applicant_pet_rabies_shot_date: {},
    applicant_pet_license_number: {},
    applicant_pet_license_city: {},
    applicant_pet_owner_name: {},
    bob: {},
};

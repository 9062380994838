import { useSelector } from 'react-redux';
import { CARD_PAYMENT_ID } from '@common/ui';
import { RootState } from 'state/store';

import type { CardInfo, PaymentType } from 'types/payments';
import { convertStringBooleans } from 'utils/convertStringBoolean';

export function useGetCardConvenienceFee() {
  const { paymentTypes, paymentInfo } = useSelector((state: RootState) => {
    return state?.payment;
  });

  function getCardConvenienceFee() {
    if (!paymentInfo) return 0;
    if (!Object.keys(paymentInfo).length) return 0;

    const { card_number: cardNumber } = paymentInfo;

    if (!cardNumber) return 0;

    return calculateConvenienceFee(
      convertStringBooleans(cardNumber),
      paymentTypes,
    );
  }

  const calculateConvenienceFee = (
    cardInfo: CardInfo,
    paymentMethods: PaymentType[],
  ) => {
    const paymentMethod = paymentMethods.find(
      (m) => m.id === cardInfo.payment_type_id,
    );
    if (!paymentMethod) return 0;

    let fee = paymentMethod.fee || 0; // Default fee

    const isCard = CARD_PAYMENT_ID.includes(cardInfo.payment_type_id);

    if (isCard) {
      const {
        is_international_card: isInternational,
        is_commercial_card: isCommercial,
        is_debit_card: isDebit,
      } = cardInfo;

      if (isDebit) {
        fee = paymentMethod.debitFee ?? fee; // Use nullish coalescing for brevity
        if (isInternational) {
          fee = paymentMethod.internationalDebitFee ?? fee;
        } else if (isCommercial) {
          fee = paymentMethod.commercialDebitFee ?? fee;
        }
      } else if (isInternational) {
        fee = paymentMethod.internationalFee ?? fee;
      } else if (isCommercial) {
        fee = paymentMethod.commercialFee ?? fee;
      }
    }

    return fee;
  };

  return getCardConvenienceFee;
}

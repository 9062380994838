"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELD_TYPE_VALIDATION = void 0;
/**
 * FIELD_TYPE_VALIDATION defines validation rules for general input types.
 * Each key corresponds to a FieldType (e.g., Email, Password, PhoneNumber).
 * This ensures consistent validation rules for all fields of a given type.
 */
var entities_1 = require("@common/entities");
var date_fns_1 = require("date-fns");
// FIELD_TYPE_VALIDATION only accepts FieldType keys
exports.FIELD_TYPE_VALIDATION = (_a = {},
    _a[entities_1.FieldType.Email] = {
        pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Invalid email format.',
        },
    },
    _a[entities_1.FieldType.Password] = {
        minLength: {
            value: 8,
            message: 'Password must be at least 8 characters long.',
        },
        pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.*])[A-Za-z\d@$!%*?&#.]{8,}$/,
            message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
        },
    },
    _a[entities_1.FieldType.PhoneNumber] = {
        pattern: {
            value: /^\(([2-9][0-9]{2})\)\s\d{3}\s-\s\d{4}$/,
            message: 'Please enter a valid phone number.',
        },
    },
    _a[entities_1.FieldType.ZipCode] = {
        pattern: {
            value: /^(\d{5}(-\d{4})?)$/,
            message: 'Invalid ZIP code format.',
        },
    },
    _a[entities_1.FieldType.SSN] = {
        pattern: {
            value: /(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}/,
            message: 'Invalid Social Security Number (SSN).',
        },
    },
    _a[entities_1.FieldType.Amount] = {
        validate: function (value) {
            if (!value)
                return true;
            var regex = /^\d{0,9}(,\d{1,3})*(\.\d{1,2})?$/;
            return regex.test(value) || 'Enter a valid amount.';
        },
    },
    _a[entities_1.FieldType.Date] = {
        validate: function (value) {
            if (!value)
                return 'Date is required.';
            var parsedDate = (0, date_fns_1.parseISO)(value);
            if (!(0, date_fns_1.isValid)(parsedDate))
                return 'Invalid date format.';
            return true;
        },
    },
    _a);

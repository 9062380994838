"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERAL_ASSISTANCE_FIELDS = void 0;
var entities_1 = require("@common/entities");
var FIELD_1 = require("./FIELD");
exports.GENERAL_ASSISTANCE_FIELDS = {
    name: 'general_assistance',
    label: 'General Assistance',
    customType: entities_1.IncomeTypes.GeneralAssistance,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.AffordableInterval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: FIELD_1.FIELDS,
};

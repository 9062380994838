"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.COAPPLICANT_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../constants");
exports.COAPPLICANT_FIELDS = [
    {
        label: 'Is this occupant 18 years of age or older?',
        type: entities_1.FieldType.Radio,
        sequence: 1,
        name: 'co_applicant_age_over_17',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS.map(function (option) { return (__assign(__assign({}, option), { testKey: "age_over_17_".concat(option.value) })); }),
        defaultValue: 'yes',
        nonRemovable: true,
    },
    {
        label: 'First Name',
        type: entities_1.FieldType.Text,
        sequence: 2,
        name: 'co_applicant_first_name',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
        nonRemovable: true,
    },
    {
        label: 'Middle Name',
        type: entities_1.FieldType.Text,
        sequence: 3,
        name: 'co_applicant_middle_name',
        customType: entities_1.FieldType.Text,
        required: false,
        enabled: true,
        nonRemovable: true,
    },
    {
        label: 'Last Name',
        type: entities_1.FieldType.Text,
        sequence: 4,
        name: 'co_applicant_last_name',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
        nonRemovable: true,
    },
    {
        label: 'Phone Number',
        type: entities_1.FieldType.PhoneNumber,
        sequence: 5,
        name: 'co_applicant_phone_number',
        customType: entities_1.FieldType.PhoneNumber,
        required: true,
        enabled: true,
        nonRemovable: true,
    },
    {
        label: 'Date of Birth',
        sequence: 6,
        name: 'co_applicant_date_of_birth',
        required: true,
        enabled: true,
        type: entities_1.FieldType.Date,
        customType: entities_1.FieldType.Date,
        nonRemovable: true,
    },
    {
        label: 'Relationship to Applicant',
        type: entities_1.FieldType.Dropdown,
        sequence: 7,
        name: 'relationship_to_applicant',
        customType: entities_1.FieldType.Dropdown,
        required: true,
        enabled: true,
        nonRemovable: true,
    },
    {
        label: 'Show date of birth for occupants under 18 years of age',
        type: entities_1.FieldType.Date,
        sequence: 8,
        name: 'date_of_birth',
        customType: entities_1.FieldType.Date,
        required: false,
        enabled: true,
        nonRemovable: false,
    },
    // BP 10/24/24 Hiding this button for now DEV-142303
    // {
    //   label: 'Allow Primary to fill out form for co-applicants',
    //   type: FieldType.Radio,
    //   sequence: 3,
    //   name: 'allow_primary_enter_coapplicant_info',
    //   customType: FieldType.Radio,
    //   required: false,
    //   enabled: true,
    // },
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagedPropertyRouteApplications = void 0;
var PagedPropertyRouteApplications = /** @class */ (function () {
    function PagedPropertyRouteApplications() {
        this.totalCount = 0;
        this.items = [];
    }
    return PagedPropertyRouteApplications;
}());
exports.PagedPropertyRouteApplications = PagedPropertyRouteApplications;

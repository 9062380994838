import { FieldErrors, UseFormTrigger } from 'react-hook-form';

import { PaymentScreening } from 'services';
import { CardInfo } from 'types/payments';

import { validateOFAC } from './ofac-form-validation';

export function isValidCard(
  data: any,
  errors: string[],
  paymentSettings: { [key: string]: any },
  paymentMethodScreening?: PaymentScreening,
  ofacError?: FieldErrors<any>,
  trigger?: UseFormTrigger<any>,
) {
  const CARD_FIELD_MAPPING: { [key: string]: string } = {
    card_number: 'Card Number',
    expiry_date: 'Exp. Date',
    cvv: 'CVV',
    postal_code: 'Zip Code',
    name_on_card: 'Name on card',
  };

  if (errors.length) {
    const field = CARD_FIELD_MAPPING[errors[0]] ?? 'details';
    throw new Error(`Please enter valid ${field}.`);
  }

  validateCardDetails(data);

  // OFAC check
  const { isScreeningEnabled = false } = paymentSettings;
  if (!isScreeningEnabled) return;

  if (!paymentMethodScreening) {
    throw new Error('Please provide account holder info.');
  }
  validateOFAC(paymentMethodScreening, ofacError ?? {}, trigger);
}

const validateCardDetails = (data: any) => {
  isCardDetailsFilled(data);
  validateCvv(data);
};

const validateCvv = (data: any) => {
  if (data.card_number.card_type_id === 8 && data.cvv.length !== 4) {
    throw new Error('Invalid CVV');
  }
  if (data.card_number.card_type_id !== 8 && data.cvv.length !== 3) {
    throw new Error('Invalid CVV');
  }
};

export function isCardDetailsFilled(data: any) {
  if (!data) {
    throw new Error('Please fill card details.');
  }

  let isValid = true;
  if (!data?.card_number) isValid = false;
  if (!data?.expiry_date) isValid = false;
  if (!data?.cvv) isValid = false;
  if (!data?.postal_code) isValid = false;
  if (!data?.name_on_card) isValid = false;

  if (!isValid) {
    throw new Error('Please fill all the details.');
  }
}

export function prepareCardRequest(
  cardDetails: any,
  expDate: string,
  paymentSettings: { [key: string]: any },
  ofacFormValue?: PaymentScreening,
) {
  const [expMonth, expYear] = expDate.split('/');
  const {
    nameOnCard,
    token,
    maskedCcNum,
    paymentTypeId,
    cvv,
    postalCode,
    isCreditCard,
    isDebitCard,
    cardTypeId,
  } = cardDetails;
  const { isScreeningEnabled = false } = paymentSettings;

  const returnData = {
    paymentTypeId,
    cardInfo: {
      nameOnCard,
      secureToken: token,
      maskedCardNumber: maskedCcNum,
      expMonth: Number(expMonth.trim()),
      expYear: Number(expYear.trim()),
      cvv: Number(cvv),
      postalCode,
      nickname: '',
      typeId: cardTypeId,
      isCreditCard,
      isDebitCard,
    },
    isScreeningEnabled,
  };

  if (isScreeningEnabled && ofacFormValue) {
    const { is_self_verified: isSelfVerified } = ofacFormValue as any;
    return {
      ...returnData,
      paymentScreening: {
        ...ofacFormValue,
        is_self_verified: isSelfVerified ?? false,
      },
    };
  }
  return returnData;
}

interface CardKeyMapping extends CardInfo {
  name_on_card: string;
  postal_code: string;
  cvv: string;
}
type CardKeys = Omit<CardKeyMapping, 'success'>;
type CardKey = keyof CardKeys;
export const cardKeyMapping = (data: CardKeys) => {
  const keyMapping = {
    is_debit_card: 'isDebitCard',
    is_check_card: 'isCheckCard',
    is_gift_card: 'isGiftCard',
    is_prepaid_card: 'isPrepaidCard',
    is_credit_card: 'isCreditCard',
    is_international_card: 'isInternationalCard',
    is_commercial_card: 'isCommercialCard',
    is_fleet_card: 'isFleetCard',
    is_hsa_fsa_account: 'isHsaFsaAccount',
    is_ebt: 'isEbt',
    is_wic_bin: 'isWicBin',
    is_durbin_bin_regulation: 'isDurbinBinRegulation',
    is_pinless_bill_pay: 'isPinlessBillPay',
    is_pinless_pos: 'isPinlessPos',
    masked_cc_num: 'maskedCcNum',
    token: 'token',
    payment_type_id: 'paymentTypeId',
    card_type_id: 'cardTypeId',
    name_on_card: 'nameOnCard',
    postal_code: 'postalCode',
    cvv: 'cvv',
  };

  const returnData: any = {};

  Object.keys(data).forEach((item: any) => {
    if (keyMapping[item as CardKey]) {
      returnData[keyMapping[item as CardKey]] = data[item as CardKey];
    }
  });
  return returnData;
};
